.header {
    grid-column: 1 / -1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-inline: 1rem;
    padding-block: .5rem;
}
@media (min-width:960px) {
    .header {
        padding-inline: 0;
    }
}



.header__title-img {
    width: auto;
    height: 1.5rem;
}
@media (min-width:960px) {
    .header__title-img {
       height: auto;
    }
}

