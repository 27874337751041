/* Default Style */
.nav-btn {
    --transition: 120ms;

    font-size: 1.5rem;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .5rem;

    position: relative;
    width:calc(100% - 12px);
    margin: 0 auto 1.5rem;

    cursor: pointer;
    border: 4px outset var(--yellow);
    border-radius: 0;
    background-color: transparent;
    border-radius: 0;
   
    color: var(--purple);
    outline: 6px solid var(--purple);

    transition: border-color var(--transition) ease, outline-color var(--transition) ease;
}

.nav-btn::before, .nav-btn::after {
    content: '';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: -1;
    opacity: 1;
    transition: opacity var(--transition) ease;
}
.nav-btn::before {
    background: linear-gradient(to left, var(--mint), white, var(--mint));
}
.nav-btn::after {
    background: linear-gradient(to left, var(--yellow), white, var(--yellow));
}

/* Pressed */
.nav-btn:active {
    border-style: inset;
}

/* Active */
.nav-btn.is-active {
    color: var(--blue-200);
    border-color: var(--mint); 
    outline-color: var(--blue-200);
}
.nav-btn.is-active::after {
    opacity:0;
}

.nav-btn__extra {
    font-size: 1rem;
}