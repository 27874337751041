.nav {
    position: fixed;
    top:0;
    left:0;
    width: min(100%, 24rem);
    height: 100%;
    padding-inline: 1rem;
    padding-block: .5rem;
    overflow-y: auto;
    overflow-x: hidden;

    background: url('../../media/img/pink_bg.webp') repeat;
    box-shadow: 2px 0 3px rgba(0,0,0,0.07),
                2px 0 6px rgba(0,0,0.03);

    transform: translate3D(calc(-100% - 5px), 0, 0);
    transition: transform 240ms ease;

    z-index: 3;
}
@media (min-width:960px) {
    .nav {
        position: relative;
        overflow:visible;
    
        background-image: none;
        box-shadow: none;

        transform: translate3D(0, 0, 0);
    }
}

.nav--is-open {
    transform: translate3D(0, 0, 0);
}


.nav__top-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.nav__close-btn {
    margin-bottom: 1rem;
    margin-inline-start: auto;
}

.nav__heading {
    font-size: 1.75rem;
    color: var(--yellow);
    text-shadow: 0px 1px 5px  rgba(0,0,0,0.8);
}

.smooch-meter {
    font-size: 1.5rem;
    font-weight: bold;
    line-height:1;
    color: var(--yellow);
    text-shadow: 0px 0px 5px  rgba(0,0,0,0.8);
}

.smooch-meter > img {
    width: 3rem;
    border: 4px solid var(--yellow);
}