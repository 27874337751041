.marquee {
    font-size: 2rem;
    font-style: italic;
    text-transform: uppercase;
    white-space: nowrap;

    position: absolute;
    top:50%;
    left:0;
    right:0;

    padding-block: 3rem;
    overflow:hidden;

    color: var(--pink);
    background-color: #000;
    border-top: 3px dashed var(--pink);
    border-bottom: 3px dashed var(--pink);

    z-index: 2;
}


.marquee > p {
    margin: 0;
    animation: marqueeMobile 20s linear infinite;
}
@media (min-width: 768px) {
    .marquee > p {
        animation-name: marqueeTablet;
    }
}
@media (min-width:960px) {
    .marquee > p {
        animation-name: marqueeDesktop;
        animation-duration: 30s;
    }
}

.marquee strong {
    font-family: var(--serif);
}

.underline {
    text-decoration: underline wavy;
}


@keyframes marqueeMobile {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-300%);
    }
}
@keyframes marqueeTablet {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-150%);
    }
}
@keyframes marqueeDesktop {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-110%);
    }
}



.viewer {
    position: relative;
    height:100%;
    width: 100%;
}
.bg-img {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit:cover;
}

.media {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit:cover;
    z-index:1;
}